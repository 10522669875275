import { Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";

const TherapeuticAreaLabel = ({ content, color }) => {
  const theme = useTheme();

  return (
    <span
      style={{
        color: color,
        maxWidth: "",
        whiteSpace: "nowrap",
        backgroundColor: "white",
        borderRadius: "11px",
        border: `1px ${color} solid`,
        padding: "2px 8px",
      }}
    >
      <Typography
        display="inline"
        variant="textButton"
        style={{
          fontWeight: 900,
          fontSize: 14,
          textTransform: "uppercase",
          letterSpacing: "0.1em",
        }}
      >
        {content}
      </Typography>
    </span>
  );
};

export default TherapeuticAreaLabel;
