import { Box, Card, Typography } from "@mui/material";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import React, { useCallback, useEffect } from "react";
import CardLabel from "./label";
import TherapeuticAreaLabel from "./therapeutic-area-label";
import { toGtmLabel } from "../../utils/index";

const CardLink = ({
  title,
  description,
  type,
  image,
  link,
  labelText,
  labelColor,
  labelTextColor,
  date,
  borderColor,
  centeredText = false,
  therapeuticAreaLabel,
  therapeuticAreaColor,
  hours,
  minutes,
  CME,
}) => {
  let slicedTitle = null;
  if (title.length > 80) {
    slicedTitle = title.slice(0, 79) + "\u2026";
  }
  useEffect(() => {
    window.dataLayer?.push({
      event: "imp_card",
      card_ta: therapeuticAreaLabel,
      card_name: title,
      card_type: toGtmLabel(type),
    });
  }, [title, type, therapeuticAreaLabel]);
  const onCardClick = useCallback(
    (event) => {
      window.dataLayer?.push({
        event: "click_card",
        card_ta: therapeuticAreaLabel,
        card_name: title,
        card_type: toGtmLabel(type),
      });
    },
    [title, type, therapeuticAreaLabel]
  );
  const { t } = useI18next();
  return (
    <Link
      to={link || "/#"}
      onClick={onCardClick}
      style={{ textDecoration: "none" }}
    >
      <Card
        variant="outlined"
        sx={{
          maxWidth: 340,
          height: 470,
          boxShadow: "0px 4px 35px rgba(0, 0, 0, 0.05)",
          border: borderColor ? `3px solid ${borderColor}` : 0,
          borderRadius: "15px",
          p: 4,
          mx: 0,
          position: "relative",
          ":hover": {
            boxShadow: "0 14px 25px rgba(0,0,0,.16)",
          },
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            paddingBottom: "0 !important",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            // justifyContent: "space-between",
          }}
        >
          <Box
            style={{
              borderRadius: "10px",
              overflow: "hidden",
              marginBottom: 20,
              width: "calc(100vw - 64px)",
              maxWidth: 276,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {image}
          </Box>
          {labelText && labelColor && (
            <div style={{ position: "absolute", top: "42px", right: "0px" }}>
              <CardLabel
                content={labelText}
                color={labelColor}
                textColor={labelTextColor}
              />
            </div>
          )}

          {date && (
            <Typography
              color="text.secondary"
              gutterBottom
              variant="textButton"
            >
              {date}
            </Typography>
          )}
          {hours && (
            <Typography
              color="text.secondary"
              gutterBottom
              variant="textButton"
            >
              {hours} {hours > 1 ? t("course.hours") : t("course.hour")}{" "}
            </Typography>
          )}
          {minutes > 0 && (
            <Typography
              color="text.secondary"
              gutterBottom
              variant="textButton"
            >
              {minutes} {t("course.minutes")}
            </Typography>
          )}
          <Typography
            gutterBottom
            component="div"
            variant="bodyStrong"
            sx={{
              fontWeight: 900,
              fontSize: "18px",
              textAlign: centeredText ? "center" : "left",
            }}
          >
            <span title={slicedTitle ? title : undefined}>
              {slicedTitle || title}
            </span>
          </Typography>
          {description && (
            <Typography
              variant="body1"
              color="text.primary"
              sx={{
                textAlign: "left",
              }}
            >
              {description}
            </Typography>
          )}
        </div>
        <div
          style={{
            paddingTop: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {therapeuticAreaLabel && (
            <TherapeuticAreaLabel
              content={therapeuticAreaLabel}
              color={therapeuticAreaColor}
            />
          )}
          {CME && (
            <div style={{ display: "flex" }}>
              <Typography
                variant="textButton"
                style={{
                  display: "flex",
                  textAlign: "center",
                  width: "110px",
                  fontWeight: 900,
                  fontSize: 14,
                  textTransform: "uppercase",
                  letterSpacing: "0.1em",
                }}
              >
                {t("course.credits")}
              </Typography>
            </div>
          )}
        </div>
      </Card>
    </Link>
  );
};

export default CardLink;
