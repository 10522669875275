import React, { Fragment } from "react";
import { Box } from "@mui/system";
import { Container, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import Carousel from "react-material-ui-carousel";

const ListOfCards = ({
  title,
  cards,
  button,
  backgroundColor,
  style = { paddingLeft: 0, paddingRight: 0 },
  disableCarousel = false, // for mobile version - if true, we show all the cards instead of a carousel
  isCentered = true,
  isTitleH1 = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  if (isMobile) {
    return (
      <ListOfCardsMobile
        title={title}
        cards={cards}
        button={button}
        backgroundColor={backgroundColor}
        disableCarousel={disableCarousel}
        isTitleH1={false}
      />
    );
  }
  return (
    <Container
      maxWidth="false"
      sx={{
        bgcolor: backgroundColor,
      }}
      style={{ paddingLeft: 0, paddingRight: 0 }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          py: 8,
          gap: 8,
        }}
        style={style}
      >
        {title && (
          <Typography
            variant={isTitleH1 ? "h1" : "h2"}
            style={{
              fontSize: "28px",
              lineHeight: "35px",
            }}
          >
            {title}
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 3,
            flexWrap: "wrap",
            justifyContent: isCentered ? "center" : "",
          }}
        >
          {cards.map((card, index) => (
            <Fragment key={`card__${index}`}>{card}</Fragment>
          ))}
        </Box>
        {button && button}
      </Container>
    </Container>
  );
};

const ListOfCardsMobile = ({
  title,
  cards,
  button,
  backgroundColor,
  disableCarousel,
  isTitleH1 = false,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        py: 6,
        backgroundColor: backgroundColor,
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      {title && (
        <Typography
          variant={isTitleH1 ? "h1" : "h2"}
          sx={{ textAlign: "center", mx: 2 }}
          style={{
            fontSize: "28px",
            lineHeight: "35px",
          }}
        >
          {title}
        </Typography>
      )}
      {disableCarousel ? (
        <React.Fragment>
          {cards.map((card, i) => (
            <Box
              key={`card__${i}`}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {card}
            </Box>
          ))}
        </React.Fragment>
      ) : (
        <CarouselCards theme={theme} cards={cards} />
      )}
      {button && (
        <Box
          sx={{ display: "flex", justifyContent: "center", minWidth: "50px" }}
        >
          {button}
        </Box>
      )}
    </Box>
  );
};

const CarouselCards = ({ theme, cards }) => {
  return (
    <Carousel
      autoPlay={false}
      cycleNavigation={false}
      navButtonsAlwaysInvisible={true}
      indicatorIconButtonProps={{
        style: {
          padding: 0,
          margin: "4px",
          color: theme.palette.grey.servier2,
        },
      }}
      activeIndicatorIconButtonProps={{
        style: {
          color: theme.palette.servier.mainBlue,
        },
      }}
    >
      {cards.map((card, i) => (
        <Box
          key={`card__${i}`}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {card}
        </Box>
      ))}
    </Carousel>
  );
};
export default ListOfCards;
