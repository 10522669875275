import { Typography } from "@mui/material";
import React from "react";

const CardLabel = ({ content, color, textColor }) => {
  return (
    <span
      style={{
        color: "white",
        backgroundColor: color,
        borderRadius: "11px 0px 0px 11px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        padding: "2px 8px",
      }}
    >
      <Typography
        display="inline"
        style={{
          fontWeight: 900,
          fontSize: 14,
          textTransform: "uppercase",
          letterSpacing: "0.1em",
          color: textColor,
        }}
      >
        {content}
      </Typography>
    </span>
  );
};

export default CardLabel;
