import { Button } from "@mui/material";
import React from "react";

const CustomButtonText = ({ text, onClick, style }) => {
  return (
    <Button
      variant="contained"
      sx={{ px: 5, py: 1 }}
      onClick={onClick}
      style={style}
    >
      {text}
    </Button>
  );
};

export default CustomButtonText;
